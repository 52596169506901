import { loginStatusState } from '@recoil/auth';
import { webSocketState } from '@recoil/webSocket';
import { ACCESS_TOKEN, getCookie } from '@utils/cookie';
import { ReactNode, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

type Props = {
  readonly children: ReactNode;
};

export default function PushManager({ children }: Props) {
  const queryClient = useQueryClient();

  const loginStatus = useRecoilValue(loginStatusState);
  const [webSocket, setWebSocket] = useRecoilState(webSocketState);

  useEffect(() => {
    // 로그아웃 case
    if (!loginStatus && webSocket) {
      webSocket.close();
      return;
    }

    // 비로그인, 이미 ws 존재
    if (!loginStatus || webSocket) {
      return;
    }

    const accessToken = getCookie(ACCESS_TOKEN);
    const ws = new WebSocket(`wss://test.steelboso.com/ws/push?t=${accessToken}`);

    if (ws) {
      ws.onmessage = (e) => {
        // push Status invalid
        queryClient.invalidateQueries(['getPushStatus']);
      };
      setWebSocket(ws);
    }
  }, [loginStatus]);

  return <>{children}</>;
}
