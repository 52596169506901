import { AxiosResponse } from 'axios';
import {
  AuthPostLoginPayload,
  AuthPostLoginData,
  AuthPostLoginError,
  AuthPostTokenRefreshPayload,
  AuthPostTokenRefreshData,
  AuthPostTokenRefreshError,
  AuthPasswordPostPasswordFindError,
  AuthPasswordPostPasswordFindData,
  AuthPasswordPostPasswordResetPayload,
  AuthPasswordPostPasswordResetData,
  AuthPasswordPostPasswordResetError,
  AuthEmailPostEmailValidationData,
  AuthEmailPostEmailValidationError,
  AuthCrnPostCrnValidationPayload,
  AuthCrnPostCrnValidationError,
  AuthCrnPostCrnValidationData,
  AuthPostRegisterData,
  AuthPostRegisterError,
  AuthPostRegisterPayload,
  AuthEmailGetEmailVerifyData,
  AuthEmailGetEmailVerifyError,
  AuthEmailPostEmailResendData,
  AuthEmailPostEmailResendError,
} from 'models/swagger/data-contracts';
import {
  AuthEmailPostEmailCheckPayload,
  AuthPasswordPostPasswordResetEmailPayload,
  resendEmailPayload,
  verifyEmailPayload,
} from 'models/Supplement';
import API from '@apis/client';

/**
 * 로그인 API
 * @function loginApi
 * @param {string} email 이메일
 * @param {string} password 비밀번호
 */
export const loginApi = ({
  email,
  password,
}: AuthPostLoginPayload): Promise<AxiosResponse<AuthPostLoginData, AuthPostLoginError>> => {
  return API.post(`/auth/login`, {
    email,
    password,
  });
};

/**
 *  새 인증 토큰 요청 API
 *  @function getNewAuthTokenApi
 *  @param {string} refresh_token 리프레쉬 토큰
 */
export const getNewAuthTokenApi = ({
  refresh_token: refreshToken,
}: AuthPostTokenRefreshPayload): Promise<AxiosResponse<AuthPostTokenRefreshData, AuthPostTokenRefreshError>> => {
  return API.post(`/auth/refresh`, {
    refresh_token: refreshToken,
  });
};

/**
 *  비밀번호 초기화 인증 메일 전송 요청 API
 *  @function sendPasswordFindEmailApi
 *  @param {string} email 이메일
 */
export const sendPasswordFindEmailApi = ({
  email,
}: AuthPasswordPostPasswordResetEmailPayload): Promise<
  AxiosResponse<AuthPasswordPostPasswordFindData, AuthPasswordPostPasswordFindError>
> => {
  return API.post(`/auth/password/find`, {
    email,
  });
};

/**
 *  비밀번호 초기화 인증 및 변경 API
 *  @function resetPasswordApi
 *  @param {string} t 인증 코드
 *  @param {string} password 비밀번호
 */
export const resetPasswordApi = ({
  t,
  password,
}: AuthPasswordPostPasswordResetPayload): Promise<
  AxiosResponse<AuthPasswordPostPasswordResetData, AuthPasswordPostPasswordResetError>
> => {
  return API.post(`/auth/password/reset`, {
    t,
    password,
  });
};

/**
 *  이메일 중복 및 형식 검사 API
 *  @function validEmailApi
 *  @param {string} email 이메일
 */
export const validEmailApi = ({
  email,
}: AuthEmailPostEmailCheckPayload): Promise<
  AxiosResponse<AuthEmailPostEmailValidationData, AuthEmailPostEmailValidationError>
> => {
  return API.post(`/auth/email/valid`, {
    email,
  });
};

/**
 *  사업자 등록번호 검증 및 확인 API
 *  @function validCompanyRegistrationNumberApi
 *  @param {string} company_registration_number 사업자 등록번호
 */
export const validCompanyRegistrationNumberApi = ({
  company_registration_number,
}: AuthCrnPostCrnValidationPayload): Promise<
  AxiosResponse<AuthCrnPostCrnValidationData, AuthCrnPostCrnValidationError>
> => {
  return API.post(`/auth/crn/valid`, {
    company_registration_number,
  });
};

/**
 *  회원가입 API
 *  @function signUpApi
 *  @param {string} email 이메일
 *  @param {string} password 비밀번호
 *  @param {string} company_name 회사명
 *  @param {string} company_registration_number 사업자등록번호
 *  @param {string} company_address 회사주소
 *  @param {string} company_ceo 대표자
 *  @param {string} name 담당자명
 *  @param {string} phone_code 전화 코드
 *  @param {string} phone 휴대폰 번호
 *  @param {string} company_department 소속부서
 *  @param {string} company_title 직함
 *  @param {boolean} marketing_approval 마케팅 수신 동의 여부
 */
export const signUpApi = ({
  email,
  password,
  company_registration_number,
  company_name,
  company_address,
  company_ceo,
  name,
  phone_code,
  phone,
  company_department,
  company_title,
  marketing_approval,
  company_registration_number_file,
  company_registration_number_file_name,
}: AuthPostRegisterPayload): Promise<AxiosResponse<AuthPostRegisterData, AuthPostRegisterError>> => {
  return API.post(`/auth/register`, {
    email,
    password,
    company_registration_number,
    company_name,
    company_address,
    company_ceo,
    name,
    phone_code,
    phone,
    company_department,
    company_title,
    marketing_approval,
    company_registration_number_file,
    company_registration_number_file_name,
  });
};

/**
 *  이메일 인증 API
 *  @function verifyEmailApi
 *  @param {t} 인증 코드
 */
export const verifyEmailApi = ({
  t,
}: verifyEmailPayload): Promise<AxiosResponse<AuthEmailGetEmailVerifyData, AuthEmailGetEmailVerifyError>> => {
  return API.get(`/auth/email/verify?t=${t}`);
};

/**
 *  이메일 재전송 API
 *  @function resendEmailApi
 *  @param {email} Email
 */
export const resendEmailApi = ({
  email,
}: resendEmailPayload): Promise<AxiosResponse<AuthEmailPostEmailResendData, AuthEmailPostEmailResendError>> => {
  return API.post(`/auth/email/resend`, { email });
};
