export enum FileTarget {
  RMLF = 'RMLF', // 견적서 파일
  SMLF = 'SMLF', // 판매 가능 자재 파일
  AVLF = 'AVLF', // 승인 업체 파일
  AR = 'AR', // 추가 요청 파일
  BRN = 'BRN', // 사업자 번호
}

export enum NEXT_PUBLIC_MODE {
  PRODUCTION = 'prod',
  DEVELOPMENT = 'dev',
  STAGING = 'staging',
  LOCALHOST = 'localhost',
}
