import axios from 'axios';
import { MONTH } from '@constants/time';
import { setCookie, getCookie, LANGUAGE, ACCESS_TOKEN, SESSION_ID_KEY } from '@utils/cookie';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

export type ActionType = 'CLICK' | 'PAGE_IN' | 'PAGE_OUT' | 'API_CALL';

export type DefaultLogRequest = {
  key?: string;
  body?: string;
};

type LogRequest = DefaultLogRequest & {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  actionType: ActionType;
  path: string;
  referrer?: string;
};

function sendLog(logRequest: LogRequest) {
  const language = getCookie(LANGUAGE) || (logRequest.path.startsWith('/en') ? 'en' : 'ko');
  const domain = process.env.NEXT_PUBLIC_DOMAIN;
  const serviceName = process.env.NEXT_PUBLIC_SERVICE_NAME;
  const sessionId =
    getCookie(SESSION_ID_KEY) ||
    (() => {
      const newSessionId = v4();
      setCookie(SESSION_ID_KEY, newSessionId, {
        maxAge: 12 * MONTH,
        domain,
        path: '/',
      });
      return newSessionId;
    })();
  const accessToken = getCookie(ACCESS_TOKEN);

  axios.post(
    `${process.env.NEXT_PUBLIC_LOG_API_URL}`,
    {
      ...logRequest,
      serviceName,
      sessionId,
      clientDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      language: language?.toUpperCase(),
      referrer: logRequest.referrer,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken ? { ['stlbs-token']: accessToken } : {}),
      },
    },
  );
}

export default function sendUserActionLog() {
  const eventHandler: { [key in ActionType]: (logRequest: DefaultLogRequest) => void } = ((prevPath = '') => ({
    CLICK(logRequest) {
      sendLog({
        ...logRequest,
        path: prevPath,
        actionType: 'CLICK',
      });
    },
    PAGE_IN(logRequest) {
      sendLog({
        ...logRequest,
        method: 'GET',
        path: (prevPath = window.location.pathname),
        actionType: 'PAGE_IN',
        referrer: window.document.referrer,
      });
    },
    PAGE_OUT(logRequest) {
      sendLog({
        ...logRequest,
        method: 'GET',
        path: prevPath,
        actionType: 'PAGE_OUT',
      });
    },
    API_CALL(logRequest) {
      // console.log({
      //   serviceName,
      //   sessionId,
      //   actionType: 'API_CALL',
      //   clientDate: new Date(),
      //   language,
      //   ...logRequest,
      // });
    },
  }))();

  return (logType: ActionType, logRequest: DefaultLogRequest) => {
    eventHandler[logType](logRequest);
  };
}
