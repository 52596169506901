import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

// loginStatus
export const loginStatusState = atom({
  key: `loginStatus/${uuidv4()}`,
  default: false,
});

export const findEmailState = atom({
  key: `email/${uuidv4()}`,
  default: '',
});

export const previousPageUrlState = atom<string | undefined>({
  key: `previousPageUrlState/${uuidv4()}`,
  default: undefined,
});
