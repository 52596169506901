import { NEXT_PUBLIC_MODE } from '@constants/service';
import { urlCompiler } from './compiler';

/**
 *  route Link url 관련 constants
 */

export const HOME = '/'; // home
export const COMPANY_INTRO = '/document/intro'; // 회사소개서
export const COMPANY_HIRE_NOTION = '/document/career'; // 채용공고
export const COMPANY_HIRE_NOTION_LIST = '/document/career/job/list'; // 채용공고 리스트
export const CAREER_JOB_RECRUIT_DETAIL = '/document/career/job/recruit';

/**
 *  auth
 */
export const LOGIN = '/auth/login'; // 로그인
export const LOGOUT = '/auth/logout'; // 로그아웃

export const PROMOTE_SIGNUP = '/auth/promoteSignUp'; // 비회원 접근 => 회원가입 유도

export const SIGNUP = '/auth/signup'; // 회원가입
export const SIGNUP_COMPLETE = '/auth/signup/complete'; // 회원가입 완료
export const NEED = '/auth/signup/need'; // 이메일 인증 미완료

export const FIND = '/auth/find'; // 아이디, 비밀번호 찾기
export const FIND_SEND = '/auth/find/send'; // 비밀번호 찾기 메일 전송 완료
export const FIND_COMPLETE = '/auth/find/complete'; // 아이디 비밀번호 찾기 완료

export const needPath = urlCompiler<{ email: string }>(NEED);
export const findPath = urlCompiler<{ findType: string }>(FIND);
/**
 *  auth end
 */

/**
 *  user
 */
export const MYPAGE = '/user/mypage'; // 마이페이지
const MYPAGE_EDIT = '/user/mypage/edit'; // 회원 정보 수정
export const MYPAGE_INTEREST_INFO = '/user/mypage/interest';

export const SIGNOUT = '/user/signout'; // 회원탈퇴
export const SIGNOUT_COMPLETE = '/user/signout/complete'; // 회원탈퇴 완료

export const REGACY = '/user/regacy'; // v2 회원 추가정보 입력

export const mypagePath = urlCompiler<{ infoType: string }>(MYPAGE);
export const mypageEditPath = urlCompiler<{ type: string }>(MYPAGE_EDIT);
/**
 *  user end
 */

/**
 *  push
 */
export const PUSH = '/push'; // 알림페이지
/**
 *  push end
 */

/**
 *  service
 */
export const SERVICE = '/service'; // 서비스 선택

export const BUYER = '/service/buyer'; // 구매 방식 선택
export const BUYER_SELECT_TYPE = '/service/buyer/select-type';
export const BUYER_TYPE = '/service/buyer/type'; // 견적서 타입 선택

export const BUYER_PURCHASE = '/service/buyer/purchase'; // 구매자 견적 요청 (프로젝트 생성)
const BUYER_PURCHASE_COMPLETE = '/service/buyer/purchase/complete'; // 구매자 견적 요청 완료
export const BUYER_SEARCH = '/service/buyer/search'; // 구매자 검색
const BUYER_SEARCH_DETAIL = '/service/buyer/search/detail'; // 구매자 상세 검색

export const searchPath = urlCompiler<{ product_type: number }>(BUYER_SEARCH);
export const searchDetailPath = urlCompiler<{ product_type: number; category_id: number; category: string }>(
  BUYER_SEARCH_DETAIL,
);
export const buyerPurchasePath = urlCompiler<{
  optionType?: string;
  status?: string;
  rfq_number_id?: number;
  type?: string;
  subType?: string;
}>(BUYER_PURCHASE);
export const buyerTypePath = urlCompiler<{ optionType?: string }>(BUYER_TYPE);
export const buyerPurchaseCompletePath = urlCompiler<{ type: 'BUY' | 'BID' | 'CMP' }>(BUYER_PURCHASE_COMPLETE);

export const SELLER = '/service/seller'; // 판매하기
export const SELLER_COMPLETE = '/service/seller/complete'; // 판매자 자재 리스트 등록 완료
export const SELLER_DETAIL = '/service/seller/detail'; // 판매하기 상세
export const SELLER_DETAIL_VIEW = '/service/seller/detail/view'; // 판매하기 상세 비회원 조회
export const SELLER_DETAIL_COMPLETE = '/service/seller/detail/complete'; // 판매하기 상세 견적서 작성 완료
export const SELLER_DETAIL_DEAD = '/service/seller/detail/dead'; // 판매하기 프로젝트 마감
export const SELLER_REREQUEST = '/service/seller/reRequest'; // 판매하기 견적서 재작성
export const SELLER_REREQUEST_COMPLETE = '/service/seller/reRequest/complete'; // 판매하기 견적서 재작성 완료
export const SELLER_PROMOTE_ALARM = '/service/seller/detail/promoteAlarm'; // 판매하기 자재 알림설정 유도

export const sellerPath = urlCompiler<{
  page?: number;
  contentType?: string;
  finish?: boolean | '';
  indifference?: boolean | '';
  productType?: string;
  projectNm?: string;
  rfqNo?: string;
}>(SELLER);
export const sellerDetailPath = urlCompiler<{
  rfq_number_id?: number;
  step?: number;
}>(SELLER_DETAIL);
export const sellerDetailViewPath = urlCompiler<{
  rfq_number_id?: number;
}>(SELLER_DETAIL_VIEW);
export const sellerReRequestPath = urlCompiler<{
  rfq_number_id?: number;
}>(SELLER_REREQUEST);
export const sellerPromoteAlarmPath = urlCompiler<{ rfq_number_id?: number }>(SELLER_PROMOTE_ALARM);

export const CART = '/service/cart'; // 장바구니

export const PROJECT = '/service/project'; // 프로젝트 관리
const PROJECT_PURCHASE = '/service/project/purchase'; // 구매자 프로젝트 상세
const PROJECT_PURCHASE_BID = '/service/project/purchase/bid'; // 구매자 - 거래문서 - 입찰용 견적서
const PROJECT_PURCHASE_BUY = '/service/project/purchase/buy'; // 구매자 - 거래문서 - 구매용 견적서, 발주서
const PROJECT_PURCHASE_BUY_COMPLEYE = '/service/project/purchase/buy/complete'; // 구매자 - 발주 완료
const PROJECT_PURCHASE_BUY_PAYMENT_COMPLEYE = '/service/project/purchase/buy/payment-complete'; // 구매자 - 결제 완료

const PROJECT_SALE_QUOTATION = '/service/project/sale/quotation'; // 판매자 - 거래문서 - 구매용 견적서, 발주서
const PROJECT_SALE_MATERIAL = '/service/project/saleMaterial'; // 판매 자재 리스트 상세

export const projectPath = urlCompiler<{
  listType: string;
  page?: number;
  project_name?: string;
  rfq_number?: string;
  finish?: boolean | '';
  kind?: string;
  sub_kind?: string;
}>(PROJECT);
export const projectPurchasePath = urlCompiler<{ rfq_number_id?: number }>(PROJECT_PURCHASE);
export const projectPurchaseBidPath = urlCompiler<{ rfq_number_id?: number }>(PROJECT_PURCHASE_BID);
export const projectPurchaseBuyPath = urlCompiler<{ rfq_number_id?: number; quotationType?: string }>(
  PROJECT_PURCHASE_BUY,
);
export const projectPurchaseBuyPaymentCompletePath = urlCompiler<{ rfq_number_id?: number }>(
  PROJECT_PURCHASE_BUY_PAYMENT_COMPLEYE,
);
export const projectPurchaseBuyCompletePath = urlCompiler<{ rfq_number_id?: number }>(PROJECT_PURCHASE_BUY_COMPLEYE);
export const projectSaleQuotationPath = urlCompiler<{ rfq_number_id?: number }>(PROJECT_SALE_QUOTATION);
export const projectSaleMaterialPath = urlCompiler<{ sale_material_id: number }>(PROJECT_SALE_MATERIAL);
/**
 *  service end
 */

// etc link
export const NAVER_BLOG = 'https://blog.naver.com/steelboso';
export const FACEBOOK_KO = 'https://www.facebook.com/profile.php?id=100089615064894';
export const FACEBOOK_EN = 'https://www.facebook.com/steelbosoglobal';
export const LINKED_IN = 'https://www.linkedin.com/company/marketofmaterial';
export const MEDIUM = 'https://medium.com/steelboso';
export const CHANNEL_TALK = 'https://steelboso.channel.io/';
//export const COMPANY_HIRE_NOTION = 'https://marketofmaterialcom.notion.site/caaf62488435427db59c1e2c7d941b4c'; // 채용공고
export const COMPANY_USE = '/document/service'; // 서비스 이용약관
export const COMPANY_POLICY = '/document/policy'; // 개인정보 처리방침
export const COMPANY_MARKETING = '/document/marketing'; // 마케팅 정보 수신동의
export const BUSINESS_INFORMATION_CONFIRM = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4358601645'; // 사업자 정보 확인
export const BLOG = '/blog';
export const FAQ = '/customer/faq';

/**
 * S3 file
 */

const prodCdnUrl = 'https://cdn.steelboso.com/';
const devCdnUrl = 'https://cdn-dev.steelboso.com/';
const cdnUrl = process.env.NEXT_PUBLIC_MODE === NEXT_PUBLIC_MODE.PRODUCTION ? prodCdnUrl : devCdnUrl;

export const ESTIMATE_FILE_KO = `${cdnUrl}common/file/form/Steelboso_quotation_format_ko_ver2.1.xlsx`; // 표준양식 엑셀파일 국문
export const ESTIMATE_FILE_EN = `${cdnUrl}common/file/form/Steelboso_quotation_format_en_ver2.1.xlsx`; // 표준양식 엑셀파일 영문
export const LANDING_VIDEO_FILE_WEBM = `${cdnUrl}common/file/landing/landing.webm`; // 랜딩페이지 배경 영상 (webm)
export const LANDING_VIDEO_FILE_MP4 = `${cdnUrl}common/file/landing/landing.mp4`; // 랜딩페이지 배경 영상 (mp4)
export const LADING_IMAGE_FILE = `${cdnUrl}common/file/landing/landing_thumbnail.webp`; // 랜딩페이지 배경 썸네일

export const COMPANY_IR_KO = `${prodCdnUrl}common/file/ir/회사소개서_steelboso_kor_f.pdf`; // 회사소개서 국문
export const COMPANY_IR_EN = `${prodCdnUrl}common/file/ir/company_profile_steelboso_eng_f.pdf`; // 회사소개서 국문

export const BUYER_KO_WEBM = `${cdnUrl}common/file/landing/buyer_ko.webm`; // 랜딩페이지 회사소개1 구매자 영상 국문 (webm)
export const BUYER_KO_MP4 = `${cdnUrl}common/file/landing/buyer_ko.mp4`; //  랜딩페이지 회사소개1 구매자 영상 국문 (mp4)
export const BUYER_KO_WEBP = `${cdnUrl}common/file/landing/buyer_ko.webp`; //  랜딩페이지 회사소개1 구매자 영상 썸네일 국문 (webp)

export const BUYER_EN_WEBM = `${cdnUrl}common/file/landing/buyer_en.webm`; // 랜딩페이지 회사소개1 구매자 영상 영어 (webm)
export const BUYER_EN_MP4 = `${cdnUrl}common/file/landing/buyer_en.mp4`; //  랜딩페이지 회사소개1 구매자 영상 영어 (mp4)
export const BUYER_EN_WEBP = `${cdnUrl}common/file/landing/buyer_en.webp`; //  랜딩페이지 회사소개1 구매자 영상 썸네일 영어 (webp)

export const SELLER_KO_WEBM = `${cdnUrl}common/file/landing/seller_ko.webm`; // 랜딩페이지 회사소개1 판매자 영상 국문 (webm)
export const SELLER_KO_MP4 = `${cdnUrl}common/file/landing/seller_ko.mp4`; // 랜딩페이지 회사소개1 판매자 영상 국문 (mp4)
export const SELLER_KO_WEBP = `${cdnUrl}common/file/landing/seller_ko.webp`; // 랜딩페이지 회사소개1 판매자 영상 썸네일 국문 (webp)

export const SELLER_EN_WEBM = `${cdnUrl}common/file/landing/seller_en.webm`; // 랜딩페이지 회사소개1 판매자 영상 영어 (webm)
export const SELLER_EN_MP4 = `${cdnUrl}common/file/landing/seller_en.mp4`; // 랜딩페이지 회사소개1 판매자 영상 영어 (mp4)
export const SELLER_EN_WEBP = `${cdnUrl}common/file/landing/seller_en.webp`; // 랜딩페이지 회사소개1 판매자 영상 썸네일 영어 (webp)

export const MANAGE_KO_WEBM = `${cdnUrl}common/file/landing/manage_ko.webm`; // 랜딩페이지 회사소개1 관리 영상 국문 (webm)
export const MANAGE_KO_MP4 = `${cdnUrl}common/file/landing/manage_ko.mp4`; // 랜딩페이지 회사소개1 관리 영상 국문 (mp4)
export const MANAGE_KO_WEBP = `${cdnUrl}common/file/landing/manage_ko.webp`; // 랜딩페이지 회사소개1 관리 영상 썸네일 국문 (webp)

export const MANAGE_EN_WEBM = `${cdnUrl}common/file/landing/manage_en.webm`; // 랜딩페이지 회사소개1 관리 영상 영어 (webm)
export const MANAGE_EN_MP4 = `${cdnUrl}common/file/landing/manage_en.mp4`; // 랜딩페이지 회사소개1 관리 영상 영어 (mp4)
export const MANAGE_EN_WEBP = `${cdnUrl}common/file/landing/manage_en.webp`; // 랜딩페이지 회사소개1 관리 영상 썸네일 영어 (webp)

export const PAYMONTHS_PAYMENT_SERVICE_INTRO = `${prodCdnUrl}common/file/payment/[외상거래 페이먼스] 서비스 소개서_스틸보소_v1.1.pdf`;
export const PAYMONTHS_PAYMENT_SERVICE_GUIDE = `${prodCdnUrl}common/file/payment/[페이먼스] 서비스 이용가이드_202404_스틸보소.pdf`;

/**
 * S3 file end
 */
