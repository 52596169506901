export enum AlertIconType {
  WARNING = 'warning',
  FOCUS = 'focus',
  BULB = 'bulb',
  QUESTION = 'question',
}

export enum TypoVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SH1 = 'sh1',
  SH2 = 'sh2',
  SH3 = 'sh3',
  SH4 = 'sh4',
  SH5 = 'sh5',
  MH1 = 'mh1',
  MH2 = 'mh2',
  MH3 = 'mh3',
  MH4 = 'mh4',
  MH5 = 'mh5',
  B1 = 'b1',
  B2 = 'b2',
  B3 = 'b3',
  B4 = 'b4',
  B5 = 'b5',
  B6 = 'b6',
}

export enum ButtonColor {
  ContainedBlack = 'contained-black',
  ContainedGray = 'contained-gray',
  ContainedBlue = 'contained-blue',
  ContainedRed = 'contained-red',
  ContainedPurple = 'contained-purple',

  OutlinedBlack = 'outlined-black',
  OutlinedBlue = 'outlined-blue',
  OutlinedGray = 'outlined-gray',
  OutlinedRed = 'outlined-red',
  OutlinedWhite = 'outlined-white',

  TransparentBlack = 'transparent-black',
  TransparentGray = 'transparent-gray',
  TransparentBlue = 'transparent-blue',
  TransparentRed = 'transparent-red',
}

export enum ButtonSize {
  Large = 'large',
  Small = 'small',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export enum BadgeColor {
  Blue = 'blue',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Gray = 'gray',
}

export enum BadgeSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum ChipColor {
  Blue = 'blue',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Gray = 'gray',
}

export enum ChipSize {
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum DividerAlign {
  LEFT = 'left',
  CENTER = 'center',
}

export enum FileInputCheckBoxType {
  TEXTAREA = 'textarea',
  DISABLED = 'disabled',
}

export enum TextInputVariant {
  DEFAULT = 'default',
  ERROR = 'error',
  FIXED = 'fixed',
}

export enum TextInputAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TextInputSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum DropdownSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum TabbarVariant {
  DEFAULT = 'default',
  DIVIDER = 'divider',
}

export enum TabbarAlign {
  LEFT = 'left',
  CENTER = 'center',
}

export enum CardSelectVariant {
  DEFAULT = 'default',
  SELECT = 'select',
  DISABLED = 'disabled',
}

export enum CardSelectSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum IconSize {
  NON = '',
  SMALL = '20px',
  MEDIUM = '24px',
  LARGE = '28px',
  MAXIMUM = '48px',
}

export enum TextButtonColor {
  Black = 'black',
  Gray = 'gray',
  White = 'white',
}

export enum TableAlign {
  LEFT = 'left',
  CENTER = 'CENTER',
}
