import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACCESS_TOKEN, getCookie, REFRESH_TOKEN, removeCookie } from '@utils/cookie';
import {
  getSaleMaterialFromSaleQuotationApi,
  getUserProfileApi,
  getUserSaleItemsApi,
  patchRegacyUserApi,
  patchUserCompanyDepartmentApi,
  patchUserCompanyInfoApi,
  patchUserCompanyTitleApi,
  patchUserMarketingApprovalApi,
  patchUserNameApi,
  patchUserPasswordApi,
  patchUserPhoneApi,
  patchUserSaleItemsApi,
  patchUserSnsApprovalApi,
  userSignOutApi,
} from '@apis/user';
import { MONTH } from '@constants/time';
import {
  UserGetUserProfileData,
  UserGetUserProfileError,
  UserProfileApprovalPatchUserMarketingApprovalError,
  UserProfileApprovalPatchUserMarketingApprovalPayload,
  UserProfileApprovalPatchUserSnsApprovalError,
  UserProfileApprovalPatchUserSnsApprovalPayload,
  UserProfileCompanyPatchUserCompanyDepartmentError,
  UserProfileCompanyPatchUserCompanyDepartmentPayload,
  UserProfileCompanyPatchUserCompanyInfoError,
  UserProfileCompanyPatchUserCompanyInfoPayload,
  UserProfileCompanyPatchUserCompanyTitleError,
  UserProfileCompanyPatchUserCompanyTitlePayload,
  UserProfileDeleteUserWithdrawError,
  UserProfilePatchRegacyUserInfoPayload,
  UserProfilePatchUserNameError,
  UserProfilePatchUserNamePayload,
  UserProfilePatchUserPasswordError,
  UserProfilePatchUserPasswordPayload,
  UserProfilePatchUserPhoneError,
  UserProfilePatchUserPhonePayload,
  UserSaleItemsPartialUpdateError,
  UserSaleItemsReadError,
  UserSaleQuotationGetSaleMaterialsFromSaleQuotationError,
} from 'models/swagger/data-contracts';
import { Toast } from '@components/_atoms/Toast';
import {
  UserSaleItemsPartialUpdatePayloadType,
  UserSaleQuotationGetSaleMaterialsFromSaleQuotationPayload,
} from 'models/Supplement';
import { useRouter } from 'next/router';
import { REGACY } from '@constants/routes/routes';

/**
 * 회원정보 가져오기 Fetch
 * @function useGetUserProfileFetch
 */
export const useGetUserProfileFetch = () => {
  const accessToken = getCookie(ACCESS_TOKEN);
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

  const {
    data: getUserProfileFetchData,
    refetch: getUserProfileRefetch,
    isLoading: getUserProfileIsLoading,
    isError: getUserProfileIsError,
  } = useQuery(
    ['user'],
    async function () {
      const result = await getUserProfileApi();
      return result?.data.data as UserGetUserProfileData['data'];
    },
    {
      staleTime: MONTH * 1000, // ms 기준이기에 1000 곱해줌
      enabled: !!accessToken,
      onError: (err: AxiosError<UserGetUserProfileError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);

          removeCookie(ACCESS_TOKEN, { domain: currentDomain, path: '/' });
          removeCookie(REFRESH_TOKEN, { domain: currentDomain, path: '/' });
        }
      },
    },
  );

  const isCustomLoading = (!getUserProfileFetchData || getUserProfileIsLoading) && !getUserProfileIsError;

  return {
    getUserProfileFetchData,
    getUserProfileRefetch,
    isCustomLoading,
    getUserProfileIsLoading,
  };
};

/**
 * 유저 회원탈퇴 Fetch
 * @function useUserSignOutFetch
 */
export const useUserSignOutFetch = () => {
  const {
    mutate: userSignOutFetchMutate,
    status: userSignOutFetchStatus,
    isLoading: userSignOutFetchIsLoading,
  } = useMutation(['userSignOut'], () => userSignOutApi(), {
    onError: (err: AxiosError<UserProfileDeleteUserWithdrawError>) => {
      if (err.response?.data?.message) {
        Toast.error(err.response.data.message.text);
      }
    },
  });

  return {
    userSignOutFetchMutate,
    userSignOutFetchStatus,
    userSignOutFetchIsLoading,
  };
};

/**
 *  비밀번호 변경 Fetch
 *  @function usePatchUserPasswordFetch
 */
export const usePatchUserPasswordFetch = () => {
  const {
    mutate: patchUserPasswordFetchMutate,
    status: patchUserPasswordFetchStatus,
    isLoading: patchUserPasswordFetchIsLoading,
  } = useMutation(['user'], ({ password }: UserProfilePatchUserPasswordPayload) => patchUserPasswordApi({ password }), {
    onError: (err: AxiosError<UserProfilePatchUserPasswordError>) => {
      if (err.response?.data?.message) {
        Toast.error(err.response.data.message.text);
      }
    },
  });

  return {
    patchUserPasswordFetchMutate,
    patchUserPasswordFetchStatus,
    patchUserPasswordFetchIsLoading,
  };
};

/**
 *  이름 변경 Fetch
 *  @function usePatchUserNameFetch
 */
export const usePatchUserNameFetch = () => {
  const {
    mutate: patchUserNameFetchMutate,
    status: patchUserNameFetchStatus,
    isLoading: patchUserNameFetchIsLoading,
  } = useMutation(['user'], ({ name }: UserProfilePatchUserNamePayload) => patchUserNameApi({ name }), {
    onError: (err: AxiosError<UserProfilePatchUserNameError>) => {
      if (err.response?.data?.message) {
        Toast.error(err.response.data.message.text);
      }
    },
  });

  return {
    patchUserNameFetchMutate,
    patchUserNameFetchStatus,
    patchUserNameFetchIsLoading,
  };
};

/**
 *  휴대폰 번호 변경 Fetch
 *  @function usePatchUserPhoneFetch
 */
export const usePatchUserPhoneFetch = () => {
  const {
    mutate: patchUserPhoneFetchMutate,
    status: patchUserPhoneFetchStatus,
    isLoading: patchUserPhoneFetchIsLoading,
  } = useMutation(
    ['user'],
    ({ phone_code, phone }: UserProfilePatchUserPhonePayload) => patchUserPhoneApi({ phone_code, phone }),
    {
      onError: (err: AxiosError<UserProfilePatchUserPhoneError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserPhoneFetchMutate,
    patchUserPhoneFetchStatus,
    patchUserPhoneFetchIsLoading,
  };
};

/**
 *  소속 부서 변경 Fetch
 *  @function usePatchUserCompanyDepartmentFetch
 */
export const usePatchUserCompanyDepartmentFetch = () => {
  const {
    mutate: patchUserCompanyDepartmentFetchMutate,
    status: patchUserCompanyDepartmentFetchStatus,
    isLoading: patchUserCompanyDepartmentFetchIsLoading,
  } = useMutation(
    ['user'],
    ({ company_department }: UserProfileCompanyPatchUserCompanyDepartmentPayload) =>
      patchUserCompanyDepartmentApi({ company_department }),
    {
      onError: (err: AxiosError<UserProfileCompanyPatchUserCompanyDepartmentError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserCompanyDepartmentFetchMutate,
    patchUserCompanyDepartmentFetchStatus,
    patchUserCompanyDepartmentFetchIsLoading,
  };
};

/**
 *  직함 변경 Fetch
 *  @function usePatchUserCompanyTitleFetch
 */
export const usePatchUserCompanyTitleFetch = () => {
  const {
    mutate: patchUserCompanyTitleFetchMutate,
    status: patchUserCompanyTitleFetchStatus,
    isLoading: patchUserCompanyTitleFetchIsLoading,
  } = useMutation(
    ['user'],
    ({ company_title }: UserProfileCompanyPatchUserCompanyTitlePayload) => patchUserCompanyTitleApi({ company_title }),
    {
      onError: (err: AxiosError<UserProfileCompanyPatchUserCompanyTitleError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserCompanyTitleFetchMutate,
    patchUserCompanyTitleFetchStatus,
    patchUserCompanyTitleFetchIsLoading,
  };
};

/**
 *  회사 정보 변경 Fetch
 *  @function usePatchUserCompanyInfoFetch
 */
export const usePatchUserCompanyInfoFetch = () => {
  const {
    mutate: patchUserCompanyInfoFetchMutate,
    status: patchUserCompanyInfoFetchStatus,
    isLoading: patchUserCompanyInfoFetchIsLoading,
  } = useMutation(
    ['user'],
    ({
      company_name,
      company_registration_number,
      company_address,
      company_ceo,
    }: UserProfileCompanyPatchUserCompanyInfoPayload) =>
      patchUserCompanyInfoApi({ company_name, company_registration_number, company_address, company_ceo }),
    {
      onError: (err: AxiosError<UserProfileCompanyPatchUserCompanyInfoError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserCompanyInfoFetchMutate,
    patchUserCompanyInfoFetchStatus,
    patchUserCompanyInfoFetchIsLoading,
  };
};

/**
 *  유저 마케팅 정보 수신 동의 여부 변경 Fetch
 *  @function usePatchUserMarketingApprovalFetch
 */
export const usePatchUserMarketingApprovalFetch = () => {
  const {
    mutate: patchUserMarketingApprovalFetchMutate,
    status: patchUserMarketingApprovalFetchStatus,
    isLoading: patchUserMarketingApprovalFetchIsLoading,
  } = useMutation(
    ['user'],
    ({ marketing_approval }: UserProfileApprovalPatchUserMarketingApprovalPayload) =>
      patchUserMarketingApprovalApi({ marketing_approval }),
    {
      onError: (err: AxiosError<UserProfileApprovalPatchUserMarketingApprovalError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserMarketingApprovalFetchMutate,
    patchUserMarketingApprovalFetchStatus,
    patchUserMarketingApprovalFetchIsLoading,
  };
};

/**
 *  유저 SNS(알림톡, 문자) 받기 여부 변경 Fetch
 *  @function usePatchUserSnsApprovalFetch
 */
export const usePatchUserSnsApprovalFetch = () => {
  const {
    mutate: patchUserSnsApprovalFetchMutate,
    status: patchUserSnsApprovalFetchStatus,
    isLoading: patchUserSnsApprovalFetchIsLoading,
  } = useMutation(
    ['user'],
    ({ sns_approval }: UserProfileApprovalPatchUserSnsApprovalPayload) => patchUserSnsApprovalApi({ sns_approval }),
    {
      onError: (err: AxiosError<UserProfileApprovalPatchUserSnsApprovalError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchUserSnsApprovalFetchMutate,
    patchUserSnsApprovalFetchStatus,
    patchUserSnsApprovalFetchIsLoading,
  };
};

/**
 *  v2 유저 추가 정보 변경 Fetch
 *  @function usePatchRegacyUserFetch
 */
export const usePatchRegacyUserFetch = () => {
  const { mutate: patchRegacyUserFetchMutate } = useMutation(
    ['regacyUser'],
    ({
      company_name,
      company_registration_number,
      company_address,
      company_ceo,
      name,
      phone_code,
      phone,
    }: UserProfilePatchRegacyUserInfoPayload) =>
      patchRegacyUserApi({
        company_name,
        company_registration_number,
        company_address,
        company_ceo,
        name,
        phone_code,
        phone,
      }),
    {
      onError: (err: AxiosError<UserProfilePatchUserNameError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return {
    patchRegacyUserFetchMutate,
  };
};

/**
 * 유저 자신의 판매 알림 기능 자재 정보를 조회한다.
 * @function useGetUserSaleItemsFetch
 */
export const useGetUserSaleItemsFetch = () => {
  const accessToken = getCookie(ACCESS_TOKEN);
  const router = useRouter();

  const { data: userSaleItemsFetchData, isLoading: userSaleItemsFetchIsLoading } = useQuery(
    ['userSaleItems'],
    async () => {
      const result = await getUserSaleItemsApi();

      if (result.data.mcode === 2008) {
        router.push(REGACY);
      }
      return result.data.data;
    },
    {
      enabled: !!accessToken,
      onError: (err: AxiosError<UserSaleItemsReadError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return { userSaleItemsFetchData, userSaleItemsFetchIsLoading };
};

/**
 * 유저 자신의 판매 알림 기능 자재 정보를 수정한다.
 * @function usePatchUserSaleItemsFetch
 */
export const usePatchUserSaleItemsFetch = () => {
  const queryClient = useQueryClient();

  const { mutate: patchUserSaleItemsFetchMutate } = useMutation(
    ['patchUserSaleItems'],
    async ({
      is_user_sale_material_push,
      delete_sale_material_list,
      create_sale_material_list,
    }: UserSaleItemsPartialUpdatePayloadType) => {
      await patchUserSaleItemsApi({ is_user_sale_material_push, delete_sale_material_list, create_sale_material_list });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSaleItems']);
        queryClient.invalidateQueries(['user']);
      },
      onError: (err: AxiosError<UserSaleItemsPartialUpdateError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );
  return {
    patchUserSaleItemsFetchMutate,
  };
};

/**
 * 유저가 판매용 견적서 요청 후, 해당 견적서 내의 등록한 아이템들을 판매 알림 기능 자재 정보 형식으로 조회한다.
 * @function useGetSaleMaterialFromSaleQuotationFetch
 */
export const useGetSaleMaterialFromSaleQuotationFetch = ({
  rfq_number_id,
}: UserSaleQuotationGetSaleMaterialsFromSaleQuotationPayload) => {
  const { data: getSaleMaterialFromSaleQuotationFetch } = useQuery(
    ['getSaleMaterialFromSaleQuotation', rfq_number_id],
    async () => {
      const result = await getSaleMaterialFromSaleQuotationApi({ rfq_number_id });
      return result.data.data;
    },
    {
      enabled: !!rfq_number_id,
      onError: (err: AxiosError<UserSaleQuotationGetSaleMaterialsFromSaleQuotationError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response.data.message.text);
        }
      },
    },
  );

  return { getSaleMaterialFromSaleQuotationFetch };
};
