import { loginStatusState } from '@recoil/auth';
import { getCookie, LANGUAGE, removeCookie } from '@utils/cookie';
import { UserProfileDataType } from 'models/SupplementType';
import { ReactNode, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

declare global {
  interface Window {
    ChannelIO?: IChannelIO;
    ChannelIOInitialized?: boolean;
  }
}

interface IChannelIO {
  c?: (...args: any) => void;
  q?: [methodName: string, ...args: any[]][];
  (...args: any): void;
}

type Props = {
  readonly children: ReactNode;
};

type ChannelTalkBootOptionProps = {
  readonly user?: UserProfileDataType;
  readonly language: string;
};

const CHANNEL_TALK_PLUGIN_KEY = 'f21724c4-e795-4d54-a404-66ff6269db52';

export default function ChannelTalkManager({ children }: Props) {
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;
  const language = getCookie(LANGUAGE);

  const loginStatus = useRecoilValue(loginStatusState);
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<UserProfileDataType>('user');

  /** 채널톡 부팅 */
  useEffect(() => {
    window.ChannelIO?.('shutdown');

    const bootOption = getChannelTalkBootOption({ user, language });

    window.ChannelIO?.('boot', bootOption);
    window.ChannelIO?.('updateUser', { language });

    return () => {
      window.ChannelIO?.('shutdown');
    };
  }, [loginStatus]);

  /**
   * 2023년 8월 3일자 빅인사이트 요구사항!!!
   */
  useEffect(() => {
    if(loginStatus && user ){
      const {email, name, phone, company_name, company_department, company_title, marketing_approval} = user;
      const userInfo = JSON.stringify({
        email, name, phone, company_name, company_department, company_title, marketing_approval
      })

      localStorage.setItem('biginsite', userInfo);
    }else if(!loginStatus){
      localStorage.removeItem('biginsite');
    }
  }, [loginStatus]);

  /** 로그아웃시 채널톡 세션 삭제(웹페이지에서의 채널톡 유저 정보 삭제) */
  useEffect(() => {
    if (!loginStatus) {
      const channelSessionCookieNumber = document.cookie.match(/ch-session-(\d+)=/);

      if (channelSessionCookieNumber?.length && channelSessionCookieNumber[1]) {
        removeCookie(`ch-session-${channelSessionCookieNumber[1]}`, { domain: currentDomain, path: '/' });
      }
    }
  }, [loginStatus]);

  return <>{children}</>;
}

export const getChannelTalkBootOption = ({ user, language }: ChannelTalkBootOptionProps) => {
  if (user) {
    const { name, email, phone, id, is_test, sns_approval, email_approval, marketing_approval } = user;

    return {
      pluginKey: CHANNEL_TALK_PLUGIN_KEY,
      language,
      memberId: id,
      unsubscribeEmail: !email_approval,
      unsubscribeTexting: !sns_approval,
      profile: {
        name,
        email,
        mobileNumber: phone,
        is_test,
        marketing_approval,
      },
    };
  } else {
    return {
      pluginKey: CHANNEL_TALK_PLUGIN_KEY,
      language,
    };
  }
};
