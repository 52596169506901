import API from '@apis/client';
import { AxiosResponse } from 'axios';
import { UserSaleQuotationGetSaleMaterialsFromSaleQuotationPayload } from 'models/Supplement';
import {
  UserGetUserProfileData,
  UserGetUserProfileError,
  UserProfileApprovalPatchUserMarketingApprovalData,
  UserProfileApprovalPatchUserMarketingApprovalError,
  UserProfileApprovalPatchUserMarketingApprovalPayload,
  UserProfileApprovalPatchUserSnsApprovalData,
  UserProfileApprovalPatchUserSnsApprovalError,
  UserProfileApprovalPatchUserSnsApprovalPayload,
  UserProfileCompanyPatchUserCompanyDepartmentData,
  UserProfileCompanyPatchUserCompanyDepartmentError,
  UserProfileCompanyPatchUserCompanyDepartmentPayload,
  UserProfileCompanyPatchUserCompanyInfoData,
  UserProfileCompanyPatchUserCompanyInfoError,
  UserProfileCompanyPatchUserCompanyInfoPayload,
  UserProfileCompanyPatchUserCompanyTitleData,
  UserProfileCompanyPatchUserCompanyTitleError,
  UserProfileCompanyPatchUserCompanyTitlePayload,
  UserProfileDeleteUserWithdrawData,
  UserProfileDeleteUserWithdrawError,
  UserProfilePatchRegacyUserInfoData,
  UserProfilePatchRegacyUserInfoPayload,
  UserProfilePatchUserNameData,
  UserProfilePatchUserNameError,
  UserProfilePatchUserNamePayload,
  UserProfilePatchUserPasswordData,
  UserProfilePatchUserPasswordError,
  UserProfilePatchUserPasswordPayload,
  UserProfilePatchUserPhoneData,
  UserProfilePatchUserPhoneError,
  UserProfilePatchUserPhonePayload,
  UserSaleItemsPartialUpdateData,
  UserSaleItemsPartialUpdateError,
  UserSaleItemsPartialUpdatePayload,
  UserSaleItemsReadData,
  UserSaleItemsReadError,
  UserSaleQuotationGetSaleMaterialsFromSaleQuotationData,
  UserSaleQuotationGetSaleMaterialsFromSaleQuotationError,
} from 'models/swagger/data-contracts';

/**
 * 유저정보 가져오기 API
 * @function getUserProfileApi
 */
export const getUserProfileApi = (): Promise<AxiosResponse<UserGetUserProfileData, UserGetUserProfileError>> => {
  return API.get(`/user/profile`);
};

/**
 * 유저 회원탈퇴 API
 * @function userSignOutApi
 */
export const userSignOutApi = (): Promise<
  AxiosResponse<UserProfileDeleteUserWithdrawData, UserProfileDeleteUserWithdrawError>
> => {
  return API.delete(`/user/profile/withdraw`);
};

/**
 *  비밀번호 변경 API
 *  @function patchUserPasswordApi
 *  @param {string} password 비밀번호
 */
export const patchUserPasswordApi = ({
  password,
}: UserProfilePatchUserPasswordPayload): Promise<
  AxiosResponse<UserProfilePatchUserPasswordData, UserProfilePatchUserPasswordError>
> => {
  return API.patch(`/user/profile/password`, { password });
};

/**
 *  이름 변경 API
 *  @function patchUserNameApi
 *  @param {string} name 이름
 */
export const patchUserNameApi = ({
  name,
}: UserProfilePatchUserNamePayload): Promise<
  AxiosResponse<UserProfilePatchUserNameData, UserProfilePatchUserNameError>
> => {
  return API.patch(`/user/profile/name`, { name });
};

/**
 *  휴대폰 번호 변경 API
 *  @function patchUserPhoneApi
 *  @param {string} phone_code 전화 코드
 *  @param {string} phone 휴대폰 번호
 */
export const patchUserPhoneApi = ({
  phone_code,
  phone,
}: UserProfilePatchUserPhonePayload): Promise<
  AxiosResponse<UserProfilePatchUserPhoneData, UserProfilePatchUserPhoneError>
> => {
  return API.patch(`/user/profile/phone`, { phone_code, phone });
};

/**
 *  소속 부서 변경 API
 *  @function patchUserCompanyDepartmentApi
 *  @param {string} company_department 소속 부서
 */
export const patchUserCompanyDepartmentApi = ({
  company_department,
}: UserProfileCompanyPatchUserCompanyDepartmentPayload): Promise<
  AxiosResponse<UserProfileCompanyPatchUserCompanyDepartmentData, UserProfileCompanyPatchUserCompanyDepartmentError>
> => {
  return API.patch(`/user/profile/company/department`, {
    company_department,
  });
};

/**
 *  직함 변경 API
 *  @function patchUserCompanyTitleApi
 *  @param {string} company_title 직함
 */
export const patchUserCompanyTitleApi = ({
  company_title,
}: UserProfileCompanyPatchUserCompanyTitlePayload): Promise<
  AxiosResponse<UserProfileCompanyPatchUserCompanyTitleData, UserProfileCompanyPatchUserCompanyTitleError>
> => {
  return API.patch(`/user/profile/company/title`, { company_title });
};

/**
 *  회사 정보 변경 API (사업자등록번호 기반)
 *  @function patchUserCompanyInfoApi
 *  @param {string} company_name 회사명
 *  @param {string} company_registration_number 사업자등록번호
 *  @param {string} company_address 회사주소
 *  @param {string} company_ceo 대표자
 */
export const patchUserCompanyInfoApi = ({
  company_name,
  company_registration_number,
  company_address,
  company_ceo,
}: UserProfileCompanyPatchUserCompanyInfoPayload): Promise<
  AxiosResponse<UserProfileCompanyPatchUserCompanyInfoData, UserProfileCompanyPatchUserCompanyInfoError>
> => {
  return API.patch(`/user/profile/company/info`, {
    company_name,
    company_registration_number,
    company_address,
    company_ceo,
  });
};

/**
 *  유저 마케팅 정보 수신 동의 여부 변경 API
 *  @function patchUserMarketingApprovalApi
 *  @param {boolean} marketing_approval 마케팅 수신 동의 여부
 */
export const patchUserMarketingApprovalApi = ({
  marketing_approval,
}: UserProfileApprovalPatchUserMarketingApprovalPayload): Promise<
  AxiosResponse<UserProfileApprovalPatchUserMarketingApprovalData, UserProfileApprovalPatchUserMarketingApprovalError>
> => {
  return API.patch(`/user/profile/approval/marketing`, {
    marketing_approval,
  });
};

/**
 *  유저 SNS(알림톡, 문자) 받기 여부 변경 API
 *  @function patchUserSnsApprovalApi
 *  @param {boolean} sns_approval SNS 받기 여부
 */
export const patchUserSnsApprovalApi = ({
  sns_approval,
}: UserProfileApprovalPatchUserSnsApprovalPayload): Promise<
  AxiosResponse<UserProfileApprovalPatchUserSnsApprovalData, UserProfileApprovalPatchUserSnsApprovalError>
> => {
  return API.patch(`/user/profile/approval/sns`, {
    sns_approval,
  });
};

/**
 *  v2 유저 추가 정보 변경 API
 *  @function patchRegacyUserApi
 *  @param {string} company_name 회사명
 *  @param {string} company_registration_number 사업자등록번호
 *  @param {string} company_address 회사주소
 *  @param {string} company_ceo 대표자
 *  @param {string} name 이름
 *  @param {string} phone_code 연락처 코드
 *  @param {string} phone 연락처
 */
export const patchRegacyUserApi = ({
  company_name,
  company_registration_number,
  company_address,
  company_ceo,
  name,
  phone_code,
  phone,
}: UserProfilePatchRegacyUserInfoPayload): Promise<
  AxiosResponse<UserProfilePatchRegacyUserInfoData, UserProfilePatchUserNameError>
> => {
  return API.patch(`/user/profile/regacy`, {
    company_name,
    company_registration_number,
    company_address,
    company_ceo,
    name,
    phone_code,
    phone,
  });
};

/**
 * 유저 자신의 판매기능자재 정보를 조회한다.
 * @function getUserSaleItemsApi
 */
export const getUserSaleItemsApi = (): Promise<AxiosResponse<UserSaleItemsReadData, UserSaleItemsReadError>> => {
  return API.get(`/user/sale/items`);
};

/**
 * 유저 자신의 판매기능자재 정보를 수정한다.
 * @function patchUserSaleItemsApi
 * @param {boolean} is_user_sale_material_push 알림 수신 유무
 * @param {array} delete_sale_material_list 알림 삭제 대상 리스트 (id))
 * @param {array} create_sale_material_list 알림 추가 대상 리스트 (object))
 */
export const patchUserSaleItemsApi = ({
  is_user_sale_material_push,
  delete_sale_material_list,
  create_sale_material_list,
}: UserSaleItemsPartialUpdatePayload): Promise<
  AxiosResponse<UserSaleItemsPartialUpdateData, UserSaleItemsPartialUpdateError>
> => {
  return API.patch(`user/sale/items`, {
    is_user_sale_material_push,
    delete_sale_material_list,
    create_sale_material_list,
  });
};

/**
 * 유저가 판매용 견적서 요청 후, 해당 견적서 내의 등록한 아이템들을 판매기능자재 정보 형식으로 조회한다.
 * @function getSaleMaterialFromSaleQuotationApi
 * @param {number} sale_quotation_id 판매용 견적 아이디
 */
export const getSaleMaterialFromSaleQuotationApi = ({
  rfq_number_id,
}: UserSaleQuotationGetSaleMaterialsFromSaleQuotationPayload): Promise<
  AxiosResponse<
    UserSaleQuotationGetSaleMaterialsFromSaleQuotationData,
    UserSaleQuotationGetSaleMaterialsFromSaleQuotationError
  >
> => {
  return API.get(`/user/sale/quotation/${rfq_number_id}/items`);
};
