import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';
import { loginStatusState } from '@recoil/auth';
import { ReactNode, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

type Props = {
  readonly children: ReactNode;
};

export default function TempAccountManager({ children }: Props) {
  const setloginStatus = useSetRecoilState(loginStatusState);
  const { getUserProfileFetchData: user } = useGetUserProfileFetch();

  useEffect(() => {
    // undefined, {} 체크
    if (user && Object.keys(user).length) {
      setloginStatus(true);
    } else {
      setloginStatus(false);
    }
  }, [user, setloginStatus]);

  return <>{children}</>;
}
