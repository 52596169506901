import { captureException, withScope } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { getErrorCodeMessage } from './getErrorCodeMessage';

export const setSentryDefaultAxiosErrorScope = (axiosError: AxiosError<any>) => {
  //error request 객체
  const { method = '', url = '', params, headers, data: requestBody } = axiosError.config;
  //error response 객체
  const { data: responseData, status } = axiosError.response ?? {};
  const errorStatus = status?.toString() || '';
  const mcode: string = responseData?.mcode?.toString() ?? '';
  const mcodeMessage = getErrorCodeMessage(responseData?.mcode as number);
  /**
   * Sentry 보고서의 error title 변경
   * @default "ApiError"
   */
  axiosError.name = mcodeMessage;

  withScope(function (scope) {
    // sentry error case 에 구분자(필터옵션) 정의
    scope.setFingerprint([method, errorStatus, url, mcode]);
    scope.setTag('mcode', mcode);
    scope.setTag('error-type', mcodeMessage);
    scope.setTag('request-api-url', url);
    scope.setTag('method', method);

    //발생한 error case 에 req 정보 추가
    scope.setContext('Api Request Detail', {
      method,
      requestUrl: url,
      params,
      requestBody,
      headers,
    });
    //발생한 error case 에 res 정보 추가
    scope.setContext('Api Response Detail', {
      status,
      responseData,
    });
    //error 전송
    captureException(axiosError);
  });
};
