import { ErrorMessageName, ErrorMessageCode } from '@constants/api';

export const getErrorCodeMessage = (mcode?: number) => {
  if (!mcode) return '';

  switch (mcode) {
    case ErrorMessageCode.UNKNOWN_SERVER_ERROR:
      return ErrorMessageName.UNKNOWN_SERVER_ERROR;
    case ErrorMessageCode.WRONG_PARAM:
      return ErrorMessageName.WRONG_PARAM;
    case ErrorMessageCode.AUTH_REQUIRED:
      return ErrorMessageName.AUTH_REQUIRED;
    case ErrorMessageCode.INVALID_TOKEN:
      return ErrorMessageName.INVALID_TOKEN;
    case ErrorMessageCode.EXPIRED_TOKEN:
      return ErrorMessageName.EXPIRED_TOKEN;
    case ErrorMessageCode.WRONG_EMAIL_PASSWORD:
      return ErrorMessageName.WRONG_EMAIL_PASSWORD;
    case ErrorMessageCode.EXIST_EMAIL:
      return ErrorMessageName.EXIST_EMAIL;
    case ErrorMessageCode.NOT_EXIST_USER:
      return ErrorMessageName.NOT_EXIST_USER;
    case ErrorMessageCode.INVALID_USER:
      return ErrorMessageName.INVALID_USER;
    default:
      return 'Api Error';
  }
};
